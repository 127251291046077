import $ from 'jquery';

$(function() {
  let content = $('#content');
  let column1 = $('#column-1');
  let column2 = $('#column-2');
  let aboutWindow = $('#about-window');
  let openSourceSudokuWindow = $('#open-source-sudoku-window');
  let emulatorWindow = $('#emulator-window');
  let indexableStrWindow = $('#indexable-str-window');

  // let logOrder = () => {
  //   console.log(aboutWindow.css('order'));
  //   console.log(openSourceSudokuWindow.css('order'));
  //   console.log(emulatorWindow.css('order'));
  //   console.log(indexableStrWindow.css('order'));
  // };

  let setOrder = () => {
    if (window.matchMedia('(max-width: 666px)').matches) {
      aboutWindow.detach().appendTo(content);
      openSourceSudokuWindow.detach().appendTo(content);
      emulatorWindow.detach().appendTo(content);
      indexableStrWindow.detach().appendTo(content);

      aboutWindow.css('order', 1);
      openSourceSudokuWindow.css('order', 2);
      emulatorWindow.css('order', 3);
      indexableStrWindow.css('order', 4);
    }
    else {
      aboutWindow.detach().appendTo(column1);
      openSourceSudokuWindow.detach().appendTo(column2);
      emulatorWindow.detach().appendTo(column1);
      indexableStrWindow.detach().appendTo(column1);

      aboutWindow.css('order', 1);
      openSourceSudokuWindow.css('order', 1);
      emulatorWindow.css('order', 2);
      indexableStrWindow.css('order', 3);
    }

    //logOrder();
  };

  setOrder();

  $(window).on('resize', () => {
    setOrder();
  });
});